import axios, {
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosInstance,
  AxiosError,
  AxiosResponse,
} from 'axios';
import type { GetTablePagesResponse } from '@components/Table/types';
import { HTTP_ERROR_STATUS_CODES } from '@constants';
import { IGNORE_API_URL_ERRORS } from '@api/httpClient.const';

export interface IHTTPResponseDetailError {
  service_message: string;
  service_status_code: number;
  service_url: string;
}

export interface IHTTPResponseWithError<T = IHTTPResponseDetailError> {
  detail: T;
}
type IRequestConfigWithCounter = InternalAxiosRequestConfig & { reqIdx: number };

export class _HttpClient {
  private readonly instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
  });

  requestsCount: IRequestConfigWithCounter[] = [];
  requestsIndex = 0;

  private onError = (text: string): void => {};
  private onSetLoading = (isLoading: boolean): void => {};

  public init(): void {
    this.instance.interceptors.request.use(this.reqInterceptor, this.reqErrInterceptor);
    this.instance.interceptors.response.use(this.resInterceptor, this.resErrInterceptor);
  }

  private readonly resInterceptor = (response: AxiosResponse): AxiosResponse['data'] => {
    this.removeRequest(response.config);
    return response.data;
  };

  private readonly resErrInterceptor = (error: AxiosError<IHTTPResponseWithError>): void => {
    this.removeRequest(error.config);
    const { message, response } = error;
    const status = response?.status;

    const isIgnoreApi = IGNORE_API_URL_ERRORS.some((url) =>
      String(error?.config?.url).includes(url)
    );

    if (
      !isIgnoreApi &&
      (status === HTTP_ERROR_STATUS_CODES.Unprocessable_entity ||
        status === HTTP_ERROR_STATUS_CODES.Not_found ||
        /5\d\d/i.test(status?.toString() ?? ''))
    ) {
      // вывод ошибок в модальное окно через ErrorBoundary
      const text = `Метод: ${response?.config.method}.\nURL: ${response?.config
        .url}.\nТекст ошибки: ${message}\n\nОтвет: ${JSON.stringify(response?.data?.detail)}`;
      this.onError(text);
    } else if (
      // при Conflict и Bad_request используем ошибки бэкенда для показа на формах
      // при Unauthorized и Forbidden показываем заглушку в UserDataContextProvider
      isIgnoreApi ||
      status === HTTP_ERROR_STATUS_CODES.Forbidden ||
      response?.status === HTTP_ERROR_STATUS_CODES.Bad_request ||
      response?.status === HTTP_ERROR_STATUS_CODES.Conflict ||
      response?.status === HTTP_ERROR_STATUS_CODES.Unauthorized
    ) {
      throw error;
    }
  };

  // @ts-expect-error check types
  private readonly removeRequest = (req): void => {
    setTimeout(() => {
      this.requestsCount = this.requestsCount.filter((arr) => arr.reqIdx !== req.reqIdx);
      if (!this.requestsCount.length) this.onSetLoading(false);
    }, 500);
  };

  private readonly addRequest = (config: InternalAxiosRequestConfig): void => {
    this.requestsIndex = this.requestsIndex + 1;
    // @ts-expect-error не копировать конфиг
    config.reqIdx = this.requestsIndex;
    this.requestsCount.push(config as IRequestConfigWithCounter);
  };

  private readonly reqInterceptor = (
    config: InternalAxiosRequestConfig
  ): InternalAxiosRequestConfig => {
    if (
      !(
        config.url?.startsWith('communication/messages/') ||
        config.url?.includes('/read/') ||
        config.url?.startsWith('communication/count/new/') ||
        config.url?.startsWith('common/tickets/count/') ||
        config.url?.startsWith('communication/companies/list/') ||
        config.url?.startsWith('operator/companies/ticket/list/') ||
        config.url?.startsWith('customer/reports/participant/list/')
      )
    )
      this.onSetLoading(true);
    this.addRequest(config);
    return config;
  };

  private readonly reqErrInterceptor = async (error: {
    config: InternalAxiosRequestConfig;
  }): Promise<InternalAxiosRequestConfig> => {
    this.removeRequest(error.config);
    return await Promise.reject(error.config);
  };

  public setErrorHandler(onError: (error: string) => void): void {
    this.onError = onError;
  }

  public setLoadHandler(onSetLoading: (isLoading: boolean) => void): void {
    this.onSetLoading = onSetLoading;
  }

  public setTokens(tokens: Record<string, string>): void {
    if (!tokens?.token) return;
    this.instance.defaults.headers.common = { Authorization: `Bearer ${tokens.token}` };
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.instance.get(url, config);
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return await this.instance.delete(url, config);
  }

  public async patch<T, R = T>(
    url: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.instance.patch(url, data, config);
  }

  public async put<T, R = T>(
    path: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.instance.put(path, data, config);
  }

  public async post<T, R = T>(
    path: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.instance.post(path, data, config);
  }

  public async fetch<T, R = T>(
    path: string,
    fetchOptions?: Record<string, unknown>
  ): Promise<GetTablePagesResponse<R>> {
    return await this.instance.post(path, fetchOptions?.body, {
      params: fetchOptions?.params,
    });
  }
}

export const HttpClient = new _HttpClient();

export class BaseRepository {
  servicePath: string;
  client: _HttpClient;

  constructor(client: _HttpClient, path: string) {
    this.servicePath = path;
    this.client = client;
  }

  getUrl(path: string): string {
    return this.servicePath + path;
  }
}
